<div *ngIf="!tokenFetched" class="lds-ring"></div>
<div class="plaid__container">
  <div class="plaid__logo">
    <img src="../../../../assets/img/plaid_logo.svg" alt="Plaid logo" />
  </div>
  <h1 class="plaid__title">Connect to Plaid</h1>
  <p>
    This is not a bank transfer request but a request to verify your business
    bank account ownership. You are required to check the two boxes at the end
    of the Plaid bank account verification if applicable. Please select all your
    business bank accounts so we can serve you best by giving you a proper
    credit rating. You must also tell us the bank account to receive the
    investment fund if you're qualified.
    <br />
    <br />
    By using the service, you agree to Plaid’s
    <a [routerLink]="[link.Terms]">Terms and Conditions</a> and
    <a [routerLink]="[link.PrivacyPolicy]">Privacy Policy</a>.
    <br />
    <br />
    Plaid will retrieve your account information. <a>Read More.</a>
  </p>
  <div class="plaid__button-container">
    <app-button
      class="plaid__button"
      [color]="buttonColor.Light"
      (click)="logout()"
    >
      Cancel
    </app-button>
    <app-button
      [color]="buttonColor.Black"
      *ngIf="tokenFetched"
      ngxPlaidLink
      [token]="linkToken"
      (Success)="onSuccess($event)"
      (Exit)="onExit($event)"
      (Event)="onEvent($event)"
      (Load)="onLoad($event)"
      (Click)="onClick($event)"
      class="plaid__button"
    >
      Continue
    </app-button>
  </div>
</div>
