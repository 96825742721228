<div class="container">
  <div class="content">
    <img
      *ngIf="showLogo && !isOnPlaidPage"
      src="../../../../assets/img/asabik-logo.svg"
      class="logo mt-11 mb-9"
    />
    <router-outlet></router-outlet>
  </div>
</div>
