<div class="modal__container">
  <div class="modal__img">
    <img src="../../../../assets/img/asabik-logo.svg" />
  </div>
  <h2 class="modal__text">
    You acknowledge that you are an adult who, on his own, is willing to take
    the risk of investing in funding a business. You understood that your
    investment can partially or entirely lose the fund you invested. You agree
    that investing in listed companies is at your own risk, and you have decided
    of your own free will to invest.
  </h2>
  <div class="modal__buttons-container">
    <app-button
      [color]="buttonColor.Secondary"
      class="btn-light modal__button"
      (click)="closeModal()"
      >Accept
    </app-button>
    <app-button
      [color]="buttonColor.Light"
      class="btn-light modal__button"
      (click)="declineStatment()"
      >Decline
    </app-button>
  </div>
</div>
