<img src="../../../../assets/img/asabik-logo.svg" class="logo mt-11 mb-9" />
<div class="app-container text-center">
  <h1 class="heading-bold text-dark mb-4">Hello!</h1>
  <p class="text-medium body-regular mb-7">
    Log in or Sign up to start using <br />
    Asabik application
  </p>
  <app-button
    [color]="buttonColor.Primary"
    [routerLink]="[link.SignUp]"
    class="mb-3"
    >Sign up</app-button
  >
  <app-button [color]="buttonColor.Secondary" [routerLink]="[link.LogIn]"
    >Log in</app-button
  >
</div>
