<div [ngSwitch]="step">
  <div *ngSwitchCase="signUpStep.SignUp" class="app-container">
    <h1 class="heading-bold text-dark mb-5">Sign up as</h1>
    <form [formGroup]="form" class="form" (submit)="signUp()">
      <div class="d-flex gap-4 mb-7">
        <app-radio
          class="w-100 text-center"
          name="role"
          formControlName="role"
          [icon]="icon.Investor"
          label="I am an investor"
          [value]="role.Investor"
        >
        </app-radio>
        <app-radio
          class="w-100 text-center"
          name="role"
          formControlName="role"
          [icon]="icon.Wallet"
          label="I am looking for investors"
          [value]="role.BusinessOwner"
        >
        </app-radio>
      </div>

      <app-input
        formControlName="email"
        class="mb-5"
        placeholder="Email"
      ></app-input>
      <app-input
        formControlName="password"
        [type]="inputType.Password"
        placeholder="Password"
      ></app-input>
      <div class="password__valid" *ngIf="form.get('password')?.touched">
        <ul>
          <li
            [ngStyle]="{
              color: hasMinLength ? 'rgba(0, 173, 0, 0.784)' : 'initial'
            }"
          >
            8 characters
          </li>
          <li
            [ngStyle]="{
              color: hasLettersAndNumbers ? 'rgba(0, 173, 0, 0.784)' : 'initial'
            }"
          >
            Letters & numbers
          </li>
          <li
            [ngStyle]="{
              color: hasCapitalLetter ? 'rgba(0, 173, 0, 0.784)' : 'initial'
            }"
          >
            1 capital letter
          </li>
          <li
            [ngStyle]="{
              color: hasSpecialCharacter ? 'rgba(0, 173, 0, 0.784)' : 'initial'
            }"
          >
            1 special character
          </li>
        </ul>
      </div>
      <div
        class="d-flex check__container mb-7"
        [ngClass]="checkContainerClass"
        (click)="toggleIconType()"
      >
        <ui-icon [type]="iconType" class="check__icon"></ui-icon>
        <p class="text-medium body-regular text-left">
          I have read and accept the<br />
          <a [routerLink]="[link.Terms]">Terms and Conditions</a> and
          <a [routerLink]="[link.PrivacyPolicy]">Privacy Policy</a>
          for registering an account.
        </p>
      </div>
      <app-button
        class="mb-3"
        [disabled]="iconType === this.icon.CheckBox"
        [color]="buttonColor.Secondary"
        >Sign up</app-button
      >
      <p class="text-medium body-regular text-center">Or</p>
      <app-button
        [disabled]="form.disabled"
        class="mt-3"
        [color]="buttonColor.Primary"
        [routerLink]="[link.LogIn]"
        >Log in
      </app-button>
    </form>
  </div>
</div>
