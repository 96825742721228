export enum Page {
  Auth = 'auth',
  LogIn = 'login',
  SignUp = 'sign-up',
  ForgotPassword = 'forgot-password',
  Dashboard = 'dashboard',
  ApplicationDetails = 'application-details',
  Survey = 'survey',
  BusinessSurvey = 'business-survey',
  SurveyStatus = 'survey-status',
  Plaid = 'plaid',
  Welcome = 'welcome',
  PrivacyPolicy = 'privacy',
  Terms = 'terms',
  EmailComfirmation = 'email-comfirmation',
  ConfirmEmail = 'confirm-email',
  ResetPassword = 'reset-password',
  MyProfile = 'profile',
  Pages = 'pages',
  AdminPanel = 'admin-panel',
  SettingsPanel = 'settings',
  RatingUpdate = 'rating-update',
  AdminDashboard = 'dashboard',
  AdminPanelBusinessOwners = 'business-owners',
  AdminPanelInvestors = 'investors',
  BusinessOwnerProfilePreview = 'business-owner-profile-preview',
  InvestmentRequest = 'investment-request',
  DetailsInvestmentRequest = 'details-investment-request',
  MonthlyReport = 'monthly-report',
  SettingsBusinessOwner = 'settings',
  BusinessOwnerProfile = 'business-owner-profile',
  PreviewBusinessOwnerProfile = 'preview-business-owner-profile',
  InstallmentsInvestmentRequest = 'installments-investment-request',
  Investments = 'investments',
  InstallmentsInvestor = 'installments-investor',
}
