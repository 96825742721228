export const returnTermListConst = [
  {
    months: 12,
  },
  {
    months: 18,
  },
  {
    months: 24,
  },
  {
    months: 36,
  },
  {
    months: 48,
  },
  {
    months: 60,
  },
];
