<div class="container">
  <div class="content">
    <mat-drawer-container class="drawer-container" autosize>
      <mat-drawer #drawer mode="over">
        <app-sidenav [drawer]="drawer"></app-sidenav>
      </mat-drawer>

      <div class="drawer-content">
        <div class="navbar-container">
          <app-navbar [drawer]="drawer"></app-navbar>
        </div>
        <div class="router-container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-drawer-container>
  </div>
</div>
