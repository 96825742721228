<div class="modal__container">
  <div class="modal__img">
    <img src="../../../../assets/img/asabik-logo.svg" />
  </div>
  <h2 class="modal__text">
    This is not a bank transfer request but a verification request of your
    business bank account ownership. You are required to check the two boxes at
    the end of the Plaid bank account verification if available. Please select
    all your business bank accounts so we can serve you best by giving you a
    proper credit rating. You must also tell us the bank account to receive the
    investment fund if you're qualified.
  </h2>
  <div class="modal__buttons-container">
    <app-button
      [color]="buttonColor.Secondary"
      class="btn-light modal__button"
      (click)="closeModal()"
      >Accept
    </app-button>
    <app-button
      [color]="buttonColor.Light"
      class="btn-light modal__button"
      (click)="declineStatment()"
      >Decline
    </app-button>
  </div>
</div>
