<div class="modal__container">
  <div class="modal__img">
    <img src="../../../../assets/img/asabik-logo.svg" />
  </div>
  <h2 class="modal__text">
    To complete your investor account creation, we must verify your ID; thus,
    you will be directed to Plaid. This is not a bank transfer request but a
    verification request of your business bank account ownership. You are
    required to check the two boxes at the end of the Plaid bank account
    verification if available. <br />
    Please select all the business bank accounts you want to use for investment
    and verification.
  </h2>
  <div class="modal__buttons-container">
    <app-button
      [color]="buttonColor.Light"
      class="btn-light modal__button"
      (click)="closeModal()"
      >Close
    </app-button>
  </div>
</div>
