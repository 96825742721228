<div class="app-container">
  <h1 class="heading-bold text-dark mb-5">Create new password</h1>
  <p class="text-medium body-regular mb-7">
    Your new password must be different from previous used passwords.
  </p>
  <form [formGroup]="form" class="form pt-4" (submit)="resetPassword()">
    <app-input
      class="mb-7"
      formControlName="password"
      placeholder="Password"
      [type]="inputType.Password"
    ></app-input>
    <app-button [disabled]="form.invalid" class="mb-3"
      >Reset password</app-button
    >
  </form>
</div>
