<div class="app-container-privancy">
  <br />
  <br />
  <p class="mb-4 mt-4">
    This Privacy Policy has been updated effective September 1, 2023
  </p>
  <h3 class="heading-bold text-dark mb-4 mt-6">Privacy Policy</h3>
  <table class="mb-7">
    <tr>
      <th>
        <h4 class="text-dark mb-2">FACTS</h4>
      </th>
      <th>
        <h4 class="text-dark mb-2">
          WHAT DOES ASABIK DO WITH YOUR PERSONAL INFORMATION?
        </h4>
      </th>
    </tr>
    <tr>
      <th>Why?</th>
      <th>
        Financial companies choose how they share your personal information.
        Federal law gives consumers the right to limit some but not all sharing.
        Federal law also requires us to tell you how we collect, share, and
        protect your personal information. Please read this notice carefully to
        understand what we do.
      </th>
    </tr>
    <tr>
      <th>What?</th>
      <th>
        The types of personal information we collect and share depend on the
        product or service you have with us. This information can include:
        <ul>
          <li>Social Security Number and income</li>
          <li>
            Account balances, transaction history, and account information
          </li>
          <li>Credit history, credit scores and employment information</li>
        </ul>
        When you are no longer our customer, we continue to share your
        information as described in this notice
      </th>
    </tr>
    <tr>
      <th>How?</th>
      <th>
        All financial companies need to share customers’ personal information to
        run their everyday business. In the section below, we list the reasons
        financial companies can share their customers’ personal information; the
        reasons Asabik chooses to share; and whether you can limit this sharing.
      </th>
    </tr>
  </table>
  <table class="mb-7">
    <tr>
      <th>
        <h4 class="heading-bold text-dark mb-4 mt-4">
          Reasons we can share your personal information
        </h4>
      </th>
      <th>Does Asabik share?</th>
      <th>Can you limit this sharing?</th>
    </tr>
    <tr>
      <th>
        For our everyday business purposes — such as to process your
        transactions, maintain your account(s), respond to court orders and
        legal investigations, or report to credit bureaus
      </th>
      <th>Yes</th>
      <th>No</th>
    </tr>
    <tr>
      <th>
        For our marketing purposes - to offer our products and services to you
      </th>
      <th>Yes</th>
      <th>No</th>
    </tr>
    <tr>
      <th>For joint marketing with other financial companies</th>
      <th>No</th>
      <th>We don’t share</th>
    </tr>
    <tr>
      <th>
        For our affiliates' everyday business purposes - information about your
        transactions and experiences
      </th>
      <th>Yes</th>
      <th>No</th>
    </tr>
    <tr>
      <th>
        For our affiliates' everyday business purposes - information about your
        creditworthiness
      </th>
      <th>No</th>
      <th>We don’t share</th>
    </tr>
    <tr>
      <th>For our affiliates to market to you</th>
      <th>No</th>
      <th>We don’t share</th>
    </tr>
    <tr>
      <th>For nonaffiliates to market to you</th>
      <th>No</th>
      <th>We don’t share</th>
    </tr>
  </table>
  <table class="mb-7">
    <tr>
      <th>
        <h4 class="heading-bold text-dark mb-4 mt-4">Questions?</h4>
      </th>
      <th class="w-100">
        Email us at <span>privacy@asabik.com</span> or visit our website
        <a href="https://asabik.com">https://asabik.com</a>
      </th>
    </tr>
  </table>
  <table class="w-100">
    <tr>
      <th class="w-100">
        <h4 class="heading-bold text-dark mb-4 mt-4">Who we are</h4>
      </th>
    </tr>
  </table>
  <table class="mb-7">
    <tr>
      <th class="w-100">Who is providing this notice?</th>
      <th>Asabik LLC</th>
    </tr>
  </table>
  <table class="w-100">
    <tr>
      <th class="w-100">
        <h4 class="heading-bold text-dark mb-4 mt-4">What we do</h4>
      </th>
    </tr>
  </table>
  <table class="mb-7">
    <tr>
      <th>How does Asabik protect my personal information?</th>
      <th class="w-100">
        To protect your personal information from unauthorized access and use,
        we use security measures that comply with federal law. These measures
        include computer safeguards and secured files and building.
      </th>
    </tr>
    <tr>
      <th>How does Asabik collect my personal information?</th>
      <th>
        We collect your personal information, for example, when you
        <ul>
          <li>Open an account or apply for investment;</li>
          <li>
            Provide us information including your income and employment
            information or give us your contact information;
          </li>
          <li>Show your government issued ID;</li>
          <li>Make a payment on your investment funding request;</li>
          <li>Deposit money or withdrawals from your account;</li>
          <li>Pay your bills or apply for funding;</li>
          <li>Make wire transfer.</li>
        </ul>
        We also collect your information from other companies.
      </th>
    </tr>
    <tr>
      <th>Why can’t I limit all sharing?</th>
      <th>
        Federal law gives you the right to limit only:
        <ul>
          <li>
            sharing for affiliates’ everyday business purposes — information
            about your creditworthiness
          </li>
          <li>affiliates from using your information to market to you</li>
          <li>sharing for nonaffiliates to market to you</li>
        </ul>
        State laws and individual companies may give you additional rights to
        limit sharing. See below for more information on your rights under state
        law.
      </th>
    </tr>
    <tr>
      <th>
        What happens when I limit sharing for an account I hold jointly with
        someone else?
      </th>
      <th>Your choices will apply to everyone on your account.</th>
    </tr>
  </table>
  <table class="w-100">
    <tr>
      <th>
        <h4 class="heading-bold text-dark mb-4 mt-4">Definitions</h4>
      </th>
    </tr>
  </table>
  <table class="mb-7">
    <tr>
      <th>Affiliates</th>
      <th>
        Companies related by common ownership or control. They can be financial
        and nonfinancial companies.
      </th>
    </tr>
    <tr>
      <th>Nonaffiliates</th>
      <th>
        Companies not related by common ownership or control. They can be
        financial and nonfinancial companies.
        <ul>
          <li>
            Nonaffiliated companies we share with include financial services
            companies, marketing companies, and other service providers.
          </li>
        </ul>
      </th>
    </tr>
    <tr>
      <th>Joint marketing</th>
      <th>
        A formal agreement between nonaffiliated financial companies that
        together market financial products or services to you.
        <ul>
          <li>Asabik doesn’t jointly market.</li>
        </ul>
      </th>
    </tr>
  </table>
  <table class="mb-7">
    <tr>
      <th>
        <h4 class="heading-bold text-dark mb-4 mt-4">
          Other Important Information
        </h4>
      </th>
    </tr>
    <tr>
      <th>
        California: If your Acorns account has a California mailing address, we
        will not share personal information we collect about you except to the
        extent permitted under California law.
        <br />
        <br />
        Vermont: If your Asabik account has a Vermont mailing address, we will
        not share personal information we collect about you with non-affiliates
        unless the law allows or you provide authorization.
      </th>
    </tr>
  </table>
  <h1 class="heading-bold text-dark mb-4 mt-6">Introduction</h1>
  <p class="mb-4 mt-4">
    Welcome to Asabik. We believe that consumers deserve the utmost respect when
    it comes to the privacy of their personal information. This privacy policy
    governs the collection, storage, and use of information and data collected
    by Asabik LLC or any of our U.S. affiliates (collectively, "Asabik," "we",
    "us", or "our"), via our website at www.asabik.com and its subdomains or any
    other website operated by us that display this Privacy Policy, by our mobile
    application, or by telephone, email, or other means (collectively, and
    together with our website, "Asabik Services"). We have prepared this Privacy
    Policy to explain how we collect, use, protect, and disclose information and
    data when business owners, investors, designated agents, or other users of
    the Asabik services (collectively, “you”) use our website or any other
    Asabik Services.
    <br />
    <br />
    Please read this Privacy Policy carefully. By accessing and using this
    website or any other Asabik Services, you confirm that you have read and
    understand this Privacy Policy in its entirety. You understand and
    acknowledge that we reserve the right to change or amend this Privacy Policy
    at any time, which will be reflected by the "effective" date above. Please
    check this webpage regularly to stay informed of updates to this Privacy
    Policy. If any material changes are made to this Privacy Policy, advance
    notice of the changes will be posted in the first section of this webpage as
    required by applicable law. You understand that you will be deemed to have
    accepted the updated Privacy Policy if you continue to use the Services
    after the new Privacy Policy takes effect
    <br />
    <br />
    If you have questions or complaints regarding our Privacy Policy or
    practices, or if you need assistance in exercising any of your choices under
    this Privacy Policy, please contact us at privacy@asabik.com. This policy
    applies to all current and former Asabik customers. Note that, in order to
    use our services, you must be 18 years of age or older. Effective January 1,
    2020, the California Consumer Privacy Act (“CCPA”) provides specific rights
    and protections to California residents. If you are a California resident,
    please see the section entitled “Notice to California Residents” below for
    more information.
    <br />
    <br />
    Please also visit our Terms of Use which establish the rules, disclaimers,
    and limitations of liability governing your use of our website.
  </p>
  <br />
  <br />
  <h3 class="text-dark mb-4">1. Information We Collect</h3>
  <h4 class="text-dark mb-4">Information provided by you</h4>
  <p class="mb-4">
    Asabik collects information when you use Asabik and supplied by you or your
    designated agent. When you use or websites or otherwise communicate with us,
    we may collect personal information that you provide to us, such as your
    name, date of birth, contact details, and business information.
    <br />
    <br />
    We collect this information when you apply for investment or register as an
    investor or investee. Additional information may be gathered during your
    subsequent use of the Asabik websites, or when we communicate with you via
    telephone, email or other means.
    <br />
    <br />
    The following categories of information may be collected:
  </p>
  <h4 class="text-dark mb-4">Investees / Investors</h4>
  <ul class="text-dark mb-4">
    <li>Individual and business names</li>
    <li>Business and home address</li>
    <li>Phone and e-mail details, including passwords</li>
    <li>Bank account information</li>
    <li>Date of birth</li>
    <li>Social security number, drivers' license, or other government IDs</li>
    <li>Income, assets, debt, and other personal and business data</li>
    <li>
      Other information provided in correspondence with us, including by email,
      and telephone
    </li>
  </ul>
  <h4 class="text-dark mb-4">Information obtained from third parties</h4>
  <p class="mb-4">
    Certain personal information must be supplied when you apply for investment
    funding request and/or register as an investor or investee, or in connection
    with servicing your account, in order to meet our legal obligations, verify
    your identity, facilitate determinations for credit eligibility, protect
    against fraud, and complete your transaction. The following categories of
    information may be collected:
  </p>
  <ul class="text-dark mb-4">
    <li>
      Your basic identifying information (for example, your name, address,
      email, telephone number, and date of birth);
    </li>
    <li>Your Social Security number;</li>
    <li>
      Your credit score, credit history and other information related to your
      creditworthiness, identity and financial transactions;
    </li>
    <li>Your employment and income information;</li>
    <li>Your bank account information and transactions;</li>
    <li>
      Government-issued identification or additional information for identity
      verification;
    </li>
    <li>Any personal information you provide us.</li>
  </ul>
  <p class="mb-4">
    By using Asabik Services and applying for investment funding for your
    business, you acknowledge that our unaffiliated, third-party providers will
    have the right, power, and authority to act on your behalf to access and
    transmit your personal and financial information from the relevant financial
    institution or consumer reporting agency, according to the terms of such
    third-party provider's privacy policy.
  </p>
  <br />
  <br />
  <h3 class="text-dark mb-4">2. How We Use Your Information</h3>
  <h4 class="text-dark mb-4">Registration & Services Provision</h4>
  <p class="mb-4">
    If you register as an investee and/or request investment by applying for
    funding, Asabik will use your information to facilitate your investment
    funding request and/or request for investment, and other services provided
    you. This may include:
  </p>
  <ul class="text-dark mb-4">
    <li>
      Generating your account profile, processing your application and verifying
      your identity and application information;
    </li>
    <li>Verify your identity and conduct appropriate due diligence;</li>
    <li>
      Register you as a user and identify you when you sign into your account;
    </li>
    <li>
      Assigning you an Asabik Rating or furnishing you with a credit score upon
      your request;
    </li>
    <li>
      Enabling automatic payments and fund transfers with other financial
      institutions;
    </li>
    <li>
      Communicating with you concerning your investment funding or your
      application, account, and/or transactions;
    </li>
    <li>
      Manage and monitor your investment funding request or application and
      update your records;
    </li>
    <li>Providing support;</li>
    <li>
      Process your business’ application and determine whether or not your
      business qualifies for our products;
    </li>
    <li>
      Service your business product account, including deducting automatic
      payments;
    </li>
    <li>
      Obtain, upon your authorization, a credit report, references, and other
      information from credit bureaus or other third parties in connection with
      your funding investment application;
    </li>
    <li>Create a marketplace investment listing on the Asabik website;</li>
    <li>Determine your business’ eligibility for funding;</li>
  </ul>
  <p class="mb-4">
    If you register as an individual investor, Asabik will use your information
    to facilitate marketplace investing. This may include:
  </p>
  <ul class="text-dark mb-4">
    <li>Creating your investor profile;</li>
    <li>
      Enabling automatic payments and fund transfers with other financial
      institutions;
    </li>
    <li>
      Communicating with you concerning your Asabik account and transactions;
    </li>
    <li>Providing support;</li>
    <li>
      Communicate with you regarding your account, respond to customer service
      requests;
    </li>
    <li>
      Facilitate investing, process your deposit account application or
      transactions;
    </li>
    <li>
      Fulfill any other purposes for which you provide it, or any other purpose
      we deem necessary or appropriate;
    </li>
  </ul>
  <h4 class="text-dark mb-4">Analysis & Data Optimization</h4>
  <p class="mb-4">
    Asabik also uses your information to conduct analyses related to our
    services. We use this information to improve credit models and our services.
    We also use this information to improve Asabik websites’ usability and to
    evaluate the success of particular marketing campaigns and other activities.
    This may include:
  </p>
  <ul class="text-dark mb-4">
    <li>Carry out statistical analysis and market research and testing;</li>
    <li>Efficiently maintain our business;</li>
    <li>Protect the security and integrity of our Site and Services;</li>
    <li>Maintain and improve our Site and Services;</li>
  </ul>
  <h4 class="text-dark mb-4">Marketing & Surveys</h4>
  <p class="mb-4">
    Asabik may also use your personal information to select you for certain
    marketing communications, newsletters, surveys and/or requests for feedback
    regarding your experience. Asabik may use your personal information both to
    select you for offers and to communicate offers to you. This may include:
  </p>
  <ul class="text-dark mb-4">
    <li>
      Improve customer acquisition through the use of advertising campaigns and
      other means, such as targeted marketing;
    </li>
    <li>
      Send marketing communications and send account update notifications;
    </li>
    <li>
      Make our website available to you and identify products and services you
      may be interested in;
    </li>
    <li>Develop, maintain, and improve our website, products, and services;</li>
  </ul>
  <h4 class="text-dark mb-4">Legal Obligatory & Security Concerns</h4>
  <p class="mb-4">
    When you apply for investment funding request with us, we will ask for
    information about the owners of your business including their name, address,
    date of birth and other information that will allow us to identify them. We
    may also ask to see your driver's license or other identifying documents.
    <br />
    <br />
    To help the government fight the funding of terrorism and money laundering
    activities, federal law requires all financial institutions to obtain,
    verify, and record information that identifies the business and each person
    who owns the business. We share with law enforcement or other government
    bodies, such as to comply with subpoenas, bankruptcy proceedings, or similar
    legal processes or for the purposes of limiting fraud. When required by
    applicable law, including, but not limited to:
  </p>
  <ul class="text-dark mb-4">
    <li>
      Investigate, prevent, or take action regarding illegal activities,
      suspected fraud, situations involving potential threats to the physical
      safety of any person, or violations of our Terms of Use, and to otherwise
      fulfill our legal obligations;
    </li>
    <li>
      Monitor compliance with and enforce this Privacy Policy and any other
      applicable agreements and policies;
    </li>
    <li>Detect and guard against potential fraud and identity theft;</li>
    <li>
      Detect security incidents and protect against malicious, deceptive,
      fraudulent, or illegal activity;
    </li>
    <li>
      Comply with our legal and regulatory obligations, and carry out regulatory
      checks;
    </li>
    <li>Enforce our agreements;</li>
    <li>Defend our legal rights and the rights of others;</li>
    <li>Implementing collection activities as needed;</li>
  </ul>
  <br />
  <br />
  <h3 class="text-dark mb-4">3. How We Share Your Information</h3>
  <p class="mb-4">
    Your information, as collected by or provided to us, may be disclosed by us
    to the following categories of third parties:
    <br />
    <br />
    <b>Investors:</b> We disclose information about your business and financial
    situation in order to operate our marketplace lending platform, including:
    <br />
    To make available certain information about the fund, the investee, to
    investors who invest servicing the fund.
    <br />
    To make information available to prospective investors who are contractually
    required to implement safeguards to protect and maintain the confidentiality
    of the information we provide.
    <br />
    Asabik may provide information about your business and financial situation,
    as well as any personal information and personal information regarding the
    individuals involved with the business that you provide to us, to be
    disclosed to the investors. Service Providers: In order to provide, operate,
    and improve Asabik Services, we may disclose your information to service
    providers who assist us in operating our website, providing market research
    and marketing our products and services, or servicing investment funding
    request, so long as the service provider contractually agrees to only use
    the data on our behalf.
    <br />
    <br />
    <b>Funding Partners:</b> In order to enable Asabik to carry out our services
    and conduct our businesses, such as marketing, investing, fund servicing,
    and collections activities, including, but not limited to: With credit
    bureaus, consumer reporting agencies, financial institutions, collections
    agencies, and fraud prevention agencies in order to, among other things,
    perform various checks, locate investee, or recover debts. With business
    credit bureaus to report investment performance. With third party lenders
    and loan marketplaces to assist you obtain alternate funding options.
    <br />
    <br />
    <b>Advertising Partners:</b> We may allow third-party advertising partners
    to set cookies and other tracking tools described in "Cookies and tracking
    technologies" above to collect information regarding your activities and
    your device (e.g., your IP address, mobile identifiers, page(s) visited,
    location, time of day). These advertising partners may use this information
    (and similar information collected from other websites) for purposes of
    delivering targeted advertisements to you
  </p>
  <br />
  <br />
  <h3 class="text-dark mb-4">4. Cookies and Tracking Technologies</h3>
  <p class="mb-4">
    Asabik and our third-party service providers may utilize cookies and other
    similar technologies (such as web beacons or pixel tags) to capture and
    remember certain information (collectively, "Technologies"). We set some
    Cookies ourselves, while separate entities set other Cookies. We use Cookies
    other entities set to provide us with useful information, to help us improve
    our Site and Services, to conduct advertising, and to analyze the
    effectiveness of advertising. You can block Cookies by changing your
    Internet browser settings.
    <br />
    <br />
    <b>Analytics:</b> We may also use Google Analytics and other service
    providers to collect information regarding visitor on our Services, to
    provide tailored advertising, and to evaluate our marketing efforts.
  </p>
  <br />
  <br />
  <h3 class="text-dark mb-4">5. Storage and security</h3>
  <p class="mb-4">
    Asabik uses significant safeguards, including physical, technical
    (electronic), and operational controls to protect your personal information,
    both during transmission and once received. To the fullest extent permitted
    by applicable law, we accept no liability if communications are
    unintentionally disclosed, intercepted by third parties, incorrectly
    delivered, or fail to deliver.
    <br />
    <br />
    For all customers, we will retain your information for as long as needed to
    provide you services, comply with our legal obligations, resolve disputes,
    conduct analysis, audits, or to enforce our agreements or as otherwise
    permitted and/or required by law.
  </p>
  <br />
  <br />
  <h3 class="text-dark mb-4">6. Privacy Choices</h3>
  <p class="mb-4">
    Receipt of marketing communications via email is voluntary. If you do not
    wish to receive these communications, you may unsubscribe using the link in
    any marketing email. All customers may contact us at
    <span>privacy@asabik.com</span> to disable optional communications. As a
    registered investor, you can update your password, email, phone number. As a
    registered investee, you may update your password, email, phone number,
    though certain changes prior to fund origination may require you to submit a
    new investment funding application. To review or request changes to any of
    your Personally Identifiable Information, please contact us at
    <span>support@asabik.com</span>.
  </p>
  <br />
  <br />
  <h3 class="text-dark mb-4">7. Children’s Privacy</h3>
  <p class="mb-4">
    The Site and Services are not intended for children under 13 years of age.
    We do not knowingly collect, use, or disclose personal information from
    children under 13.
  </p>
  <br />
  <br />
  <h3 class="text-dark mb-4">8. How to Contact Us</h3>
  <p class="mb-4">
    To contact us for questions or concerns about our privacy policies or
    practices please email us at <span>privacy@asabik.com</span> or visit our
    website <a href="https://asabik.com">https://asabik.com</a>
  </p>
  <br />
  <br />
</div>
