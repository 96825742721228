export enum Icon {
  Alert = 'alert',
  Exclamation = 'exclamation',
  Close = 'close',
  Info = 'info',
  Investor = 'investor',
  Wallet = 'wallet',
  ArrowLeft = 'arrow-left',
  Menu = 'menu',
  Home = 'home',
  Companies = 'companies',
  Logout = 'logout',
  Ok = 'ok',
  Ok2 = 'ok2',
  AddImg = 'add-img',
  OpenEye = 'open-eye',
  Preview = 'preview',
  Delete = 'delete',
  Add = 'add',
  AdminLogout = 'admin-logout',
  People = 'people',
  Suitcase = 'suitcase',
  Download = 'download',
  Edit = 'edit',
  Settings = 'settings',
  Save = 'save',
  Filter = 'filter',
  Search = 'search',
  Block = 'block',
  Unblock = 'unblock',
  Check = 'check',
  CheckBox = 'check-box',
  AlertBox = 'alert-box',
  ArrowUp = 'arrow-up',
  ArrowDown = 'arrow-down',
  Plaid = 'plaid-logo',
  Investment = 'investment',
  MyInvestments = 'my-investments',
  Spin = 'spin',
  Upload = 'upload',
  Rating = 'rating-update',
  SupportAgent = 'support-agent',
}
