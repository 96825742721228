export const questionsConst = [
  {
    body: 'The sale of the products services or from any transaction involves advertisers of pork, alcohol, gambling, and tobacco?',
  },
  {
    body: 'The sale of product or services or from any transaction involves production, distribution and broadcasting of music, movies, cinema, television shows and musical shows?',
  },
  {
    body: 'The sale of products or services or any transaction involves alcohol?',
  },
  {
    body: 'The sale of products or services or any transaction involves financial services, including loans to others and payment with interest?',
  },
  {
    body: 'The sale of products or services or any transaction involves gambling, casinos or any other bidding game?',
  },
  {
    body: 'The sale of products or services or any transaction involves pornography?',
  },
  {
    body: 'The sale of products or services or any transaction involves tobacco or other substances that harm humans?',
  },
  {
    body: 'The sale of products, services, or any transaction involves the sale of pork, beacon, or any food that can harm humans?',
  },
  {
    body: 'The sale of products, services, or any transaction involves the sale of weapons, firearms, or military equipment?',
  },
  {
    body: 'The sale of products, services, or any transaction involving the music industry, the sale of music or instruments or anything related to music?',
  },
  {
    body: 'Money Laundering?',
  },
  {
    body: 'The sale of product or services that harm environment, planet, water, air, human, animals, and plants?',
  },
];
