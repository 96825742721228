<div class="app-container">
  <h1 class="heading-bold text-dark mb-5">Forgot password?</h1>
  <p class="text-medium body-regular mb-7">
    Enter your email address and we'll send you a link to reset your password
  </p>
  <form [formGroup]="form" class="form pt-4" (submit)="sendResetLink()">
    <app-input
      formControlName="email"
      class="mb-7"
      placeholder="Email"
    ></app-input>
    <app-button
      [disabled]="form.invalid"
      class="mb-3"
      [color]="buttonColor.Secondary"
      >Send reset link</app-button
    >
    <app-button
      class="mb-3"
      [color]="buttonColor.Light"
      [routerLink]="[link.LogIn]"
      >Back to log in</app-button
    >
  </form>
</div>
