<div class="modal__container">
  <div class="modal__img">
    <img src="../../../../assets/img/asabik-logo.svg" />
  </div>
  <h2 class="modal__text">
    Do you want to change the status of the investment from "Manual processing"
    and accept the investment or decline?
  </h2>
  <div class="modal__buttons-container">
    <app-button
      [color]="buttonColor.Primary"
      class="btn-light modal__button"
      (click)="acceptInvestmentRequest()"
    >
      Accept
    </app-button>
    <app-button
      [color]="buttonColor.Light"
      class="btn-light modal__button"
      (click)="declineInvestmentRequest()"
    >
      Decline
    </app-button>
  </div>
</div>
